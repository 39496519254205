import React from 'react';
import UpcomingEventsList from '../../events_index/components/upcoming_events_list/upcoming_events_list';
import PastEventsList from '../../events_index/components/past_events_list/past_events_list';
import { EVENTS_PATH } from '../../utilities/devex_paths';

interface Props {
  showUpcomingEvents: boolean;
  showPastEvents: boolean;
}

const Events: React.FC<Props> = ({ showUpcomingEvents, showPastEvents }) => {
  let eventsTitle = 'Devex events';

  if (showUpcomingEvents && !showPastEvents) {
    eventsTitle = 'Upcoming events';
  }
  if (!showUpcomingEvents && showPastEvents) {
    eventsTitle = 'Past events';
  }

  return (
    <div>
      <h2 className='emphasized mb-4'>{eventsTitle}</h2>
      {showUpcomingEvents && (
        <div>
          {showPastEvents && <h3 className='fs-5 mb-3'>Upcoming events</h3>}
          <UpcomingEventsList numberOfEventsOnMount={3} showAllEventsLogic={false} />
        </div>
      )}
      {showPastEvents && (
        <div>
          {showUpcomingEvents && <h3 className='fs-5 mb-3 mt-45'>Past events</h3>}
          <PastEventsList showMoreEventsLogic={false} numberOfEvents={4} />
        </div>
      )}
      <div className='mt-45'>
        <a className='strong link-blue-orange me-2' href={EVENTS_PATH}>
          Discover more Devex events
        </a>
        <i className='fal icon-angle-right text-orange-devex'></i>
      </div>
    </div>
  );
};

export default Events;
